import "sanitize.css"
import "./src/styles/global.css"
import "./src/assets/fonts/stylesheet.css"
import NProgress from "nprogress"
import React from "react"
import { Provider as ReduxProvider } from "react-redux"
import store from "./src/redux/store"

const setLocation = () => {
  if (window) {
    const { protocol, hostname, pathname, search } = document.location

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      originalLocation: `${protocol}//${hostname}${pathname}${search}`,
    })
  }
}

export const onClientEntry = () => {
  const options = {
    showSpinner: false,
    trickleSpeed: 50,
    color: "#F26722", // brandOrange
  }
  const styles = `
    #nprogress {
     pointer-events: none;
    }
    #nprogress .bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${options.color};
      z-index: 9000;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
      box-shadow: 0 0 10px ${options.color}, 0 0 5px ${options.color};
      opacity: 1.0;
    }
  `

  const styleNode = document.createElement(`style`)
  styleNode.id = `nprogress-styles`
  styleNode.innerHTML = styles
  document.head.appendChild(styleNode)

  NProgress.configure(options)
}

export const onInitialClientRender = () => {
  setLocation()
}

export const onRouteUpdateDelayed = () => {
  NProgress.start()
}

export const onRouteUpdate = ({ location }) => {
  window.previousPath = window.currentPath || ""
  window.currentPath = location.pathname
  NProgress.done()
}

export const wrapRootElement = ({ element }) => {
  return <ReduxProvider store={store}>{element}</ReduxProvider>
}
